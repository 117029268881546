$(document).ready(function(){
    $( "#disclaimerToggle" ).click(function(event) {
		event.preventDefault();
		$.ajax({
			type: "POST",
			url: ""+$(this).attr("data-url")+"",
			success: function(data) {
				document.location.reload();
			}
		});
	});	
});